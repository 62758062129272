.markdown-body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 16px;
    line-height: 1.5;
    word-wrap: break-word;
}

.markdown-body::before {
    display: table;
    content: ""
}

.markdown-body::after {
    display: table;
    clear: both;
    content: "";
}

.markdown-body>*:first-child {
    margin-top: 0 !important;
}

.markdown-body>*:last-child {
    margin-bottom: 0 !important;
}

.markdown-body a:not([href]) {
    color: inherit;
    text-decoration: none;
}

.markdown-body .absent {
    color: #c00;
}

.markdown-body .anchor {
    float: left;
    padding-right: 4px;
    margin-left: -20px;
    line-height: 1
}

.markdown-body .anchor:focus {
    outline: none;
}

.markdown-body p,
.markdown-body blockquote,
.markdown-body ul,
.markdown-body ol,
.markdown-body dl,
.markdown-body table,
.markdown-body pre {
    margin-top: 0;
    margin-bottom: 16px;
}

.markdown-body hr {
    height: 0.25em;
    padding: 0;
    margin: 24px 0;
    background-color: #e7e7e7;
    border: 0;
}

.markdown-body blockquote {
    padding: 0 1em;
    color: #777;
    border-left: 0.25em solid #ddd;
}
.night .markdown-body blockquote{
    color: #bcbcbc;
}

.markdown-body blockquote>:first-child {
    margin-top: 0;
}

.markdown-body blockquote>:last-child {
    margin-bottom: 0;
}

.markdown-body kbd {
    display: inline-block;
    padding: 3px 5px;
    font-size: 11px;
    line-height: 10px;
    color: #555;
    vertical-align: middle;
    background-color: #fcfcfc;
    border: solid 1px #ccc;
    border-bottom-color: #bbb;
    border-radius: 3px;
    box-shadow: inset 0 -1px 0 #bbb;
}

.markdown-body .loweralpha {
    list-style-type: lower-alpha;
}

.markdown-body h1,
.markdown-body h2,
.markdown-body h3,
.markdown-body h4,
.markdown-body h5,
.markdown-body h6 {
    margin-top: 24px;
    margin-bottom: 16px;
    font-weight: 600;
    line-height: 1.25;
}

.night .markdown-body h1,
.night .markdown-body h2,
.night .markdown-body h3,
.night .markdown-body h4,
.night .markdown-body h5,
.night .markdown-body h6 {
    color: #ddd;
}

.markdown-body h1 .fa-link,
.markdown-body h2 .fa-link,
.markdown-body h3 .fa-link,
.markdown-body h4 .fa-link,
.markdown-body h5 .fa-link,
.markdown-body h6 .fa-link {
    color: #000;
    vertical-align: middle;
    visibility: hidden;
    font-size: 16px;
}

.night .markdown-body h1 .fa-link,
.night .markdown-body h2 .fa-link,
.night .markdown-body h3 .fa-link,
.night .markdown-body h4 .fa-link,
.night .markdown-body h5 .fa-link,
.night .markdown-body h6 .fa-link {
    color: #fff;
}

.markdown-body h1:hover .anchor,
.markdown-body h2:hover .anchor,
.markdown-body h3:hover .anchor,
.markdown-body h4:hover .anchor,
.markdown-body h5:hover .anchor,
.markdown-body h6:hover .anchor {
    text-decoration: none;
}

.markdown-body h1:hover .anchor .fa-link,
.markdown-body h2:hover .anchor .fa-link,
.markdown-body h3:hover .anchor .fa-link,
.markdown-body h4:hover .anchor .fa-link,
.markdown-body h5:hover .anchor .fa-link,
.markdown-body h6:hover .anchor .fa-link {
    visibility: visible;
}

.markdown-body h1 tt,
.markdown-body h1 code,
.markdown-body h2 tt,
.markdown-body h2 code,
.markdown-body h3 tt,
.markdown-body h3 code,
.markdown-body h4 tt,
.markdown-body h4 code,
.markdown-body h5 tt,
.markdown-body h5 code,
.markdown-body h6 tt,
.markdown-body h6 code {
    font-size: inherit;
}

.markdown-body h1 {
    padding-bottom: 0.3em;
    font-size: 2em;
    border-bottom: 1px solid #eee;
}

.markdown-body h2 {
    padding-bottom: 0.3em;
    font-size: 1.5em;
    border-bottom: 1px solid #eee;
}

.markdown-body h3 {
    font-size: 1.25em;
}

.markdown-body h4 {
    font-size: 1em;
}

.markdown-body h5 {
    font-size: 0.875em;
}

.markdown-body h6 {
    font-size: 0.85em;
    color: #777
}



.markdown-body ul,
.markdown-body ol {
    padding-left: 2em
}

.markdown-body ul.no-list,
.markdown-body ol.no-list {
    padding: 0;
    list-style-type: none;
}

.markdown-body ul ul,
.markdown-body ul ol,
.markdown-body ol ol,
.markdown-body ol ul {
    margin-top: 0;
    margin-bottom: 0
}

.markdown-body li>p {
    margin-top: 16px
}

.markdown-body li+li {
    margin-top: 0.25em;
}

.markdown-body dl {
    padding: 0;
}

.markdown-body dl dt {
    padding: 0;
    margin-top: 16px;
    font-size: 1em;
    font-style: italic;
    font-weight: bold;
}

.markdown-body dl dd {
    padding: 0 16px;
    margin-bottom: 16px;
}

.markdown-body table {
    display: block;
    width: 100%;
    overflow: auto;
    word-break: normal;
    word-break: keep-all;
}

.markdown-body table th {
    font-weight: bold
}

.markdown-body table th,
.markdown-body table td {
    padding: 6px 13px;
    border: 1px solid #ddd;
}

.markdown-body table tr {
    background-color: #fff;
    border-top: 1px solid #ccc;
}
.night .markdown-body table tr {
    background-color: #5f5f5f;
}

.markdown-body table tr:nth-child(2n) {
    background-color: #f8f8f8;
}

.night .markdown-body table tr:nth-child(2n){

    background-color: #4f4f4f;
}

.markdown-body img {
    max-width: 100%;
    box-sizing: content-box;
    background-color: #fff;
}

.markdown-body img[align=right] {
    padding-left: 20px;
}

.markdown-body img[align=left] {
    padding-right: 20px;
}

.markdown-body .emoji {
    max-width: none;
    vertical-align: text-top;
    background-color: transparent;
}

.markdown-body span.frame {
    display: block;
    overflow: hidden;
}

.markdown-body span.frame>span {
    display: block;
    float: left;
    width: auto;
    padding: 7px;
    margin: 13px 0 0;
    overflow: hidden;
    border: 1px solid #ddd;
}

.markdown-body span.frame span img {
    display: block;
    float: left;
}

.markdown-body span.frame span span {
    display: block;
    padding: 5px 0 0;
    clear: both;
    color: #333;
}

.markdown-body span.align-center {
    display: block;
    overflow: hidden;
    clear: both;
}

.markdown-body span.align-center>span {
    display: block;
    margin: 13px auto 0;
    overflow: hidden;
    text-align: center;
}

.markdown-body span.align-center span img {
    margin: 0 auto;
    text-align: center;
}

.markdown-body span.align-right {
    display: block;
    overflow: hidden;
    clear: both;
}

.markdown-body span.align-right>span {
    display: block;
    margin: 13px 0 0;
    overflow: hidden;
    text-align: right;
}

.markdown-body span.align-right span img {
    margin: 0;
    text-align: right;
}

.markdown-body span.float-left {
    display: block;
    float: left;
    margin-right: 13px;
    overflow: hidden;
}

.markdown-body span.float-left span {
    margin: 13px 0 0;
}

.markdown-body span.float-right {
    display: block;
    float: right;
    margin-left: 13px;
    overflow: hidden;
}

.markdown-body span.float-right>span {
    display: block;
    margin: 13px auto 0;
    overflow: hidden;
    text-align: right;
}

.markdown-body code,
.markdown-body tt {
    padding: 0;
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    margin: 0;
    font-size: 85%;
    background-color: rgba(0, 0, 0, 0.04);
    border-radius: 3px;
}

.night .markdown-body code,
.night .markdown-body tt {

    color: #eee;
    background-color: rgba(230, 230, 230, 0.36);

}

.markdown-body code::before,
.markdown-body code::after,
.markdown-body tt::before,
.markdown-body tt::after {
    letter-spacing: -0.2em;
    content: "\00a0";
}

.markdown-body code br,
.markdown-body tt br {
    display: none;
}

.markdown-body del code {
    text-decoration: inherit;
}

.markdown-body pre {
    word-wrap: normal;
}

.markdown-body pre>code {
    padding: 0;
    margin: 0;
    font-size: 100%;
    word-break: normal;
    white-space: pre;
    background: transparent;
    border: 0;
}

.markdown-body .highlight {
    margin-bottom: 16px;
}

.markdown-body .highlight pre {
    margin-bottom: 0;
    word-break: normal;
}

.markdown-body .highlight pre,
.markdown-body pre {
    padding: 16px;
    overflow: auto;
    font-size: 85%;
    line-height: 1.45;
    background-color: #f7f7f7;
    border-radius: 3px;
}

.markdown-body pre code,
.markdown-body pre tt {
    display: inline;
    max-width: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    line-height: inherit;
    word-wrap: normal;
    background-color: transparent;
    border: 0;
}

.markdown-body pre code::before,
.markdown-body pre code::after,
.markdown-body pre tt::before,
.markdown-body pre tt::after {
    content: normal;
}

.markdown-body .csv-data td,
.markdown-body .csv-data th {
    padding: 5px;
    overflow: hidden;
    font-size: 12px;
    line-height: 1;
    text-align: left;
    white-space: nowrap;
}

.markdown-body .csv-data .blob-line-num {
    padding: 10px 8px 9px;
    text-align: right;
    background: #fff;
    border: 0;
}

.markdown-body .csv-data tr {
    border-top: 0;
}

.markdown-body .csv-data th {
    font-weight: bold;
    background: #f8f8f8;
    border-top: 0;
}

.markdown-body kbd {
    display: inline-block;
    padding: 3px 5px;
    font-size: 11px;
    line-height: 10px;
    color: #555;
    vertical-align: middle;
    background-color: #fcfcfc;
    border: solid 1px #ccc;
    border-bottom-color: #bbb;
    border-radius: 3px;
    box-shadow: inset 0 -1px 0 #bbb;
}

.news .alert .markdown-body blockquote {
    padding: 0 0 0 40px;
    border: 0 none;
}

.activity-tab .news .markdown-body blockquote,
.activity-tab .news .alert .commits {
    padding-left: 0;
}

.task-list-item {
    list-style-type: none;
}

.task-list-item label {
    font-weight: normal;
}

.task-list-item.enabled label {
    cursor: pointer;
}

.task-list-item+.task-list-item {
    margin-top: 3px;
}

.task-list-item-checkbox {
    float: left;
    margin: 0.31em 0 0.2em -1.3em !important;
    vertical-align: middle;
    cursor: default !important;
}
