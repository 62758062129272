.markdown-body.slides {
  position: relative;
  z-index: 1;
  color: #222;
}

.markdown-body.slides::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background-color: currentColor;
  box-shadow: 0 0 0 50vw;
}

.markdown-body.slides section[data-markdown] {
  position: relative;
  margin-bottom: 1.5em;
  background-color: #fff;
  text-align: center;
}

.markdown-body.slides section[data-markdown] code {
  text-align: left;
}

.markdown-body.slides section[data-markdown]::before {
  content: '';
  display: block;
  padding-bottom: 56.23%;
}

.markdown-body.slides section[data-markdown] > div:first-child {
  position: absolute;
  top: 50%;
  left: 1em;
  right: 1em;
  transform: translateY(-50%);
  max-height: 100%;
  overflow: hidden;
}

.markdown-body.slides section[data-markdown] > ul {
  display: inline-block;
}

.markdown-body.slides > section > section + section::after {
  content: '';
  position: absolute;
  top: -1.5em;
  right: 1em;
  height: 1.5em;
  border: 3px solid #777;
}

.markdown-body.slides aside.notes {
  display: none;
}

.markdown-body.slides ul, .markdown-body.slides ol {
  display: inline-block;
  text-align: left;
  margin: 0 0 0 1em;
  padding: 0;
}

.markdown-body.slides table {
  width: 50%;
  margin: 0 auto;
  border-collapse: collapse;
  border-spacing: 0;
  display: table;
}

.markdown-body.slides table th, .markdown-body.slides table td {
  text-align: left;
  padding: 0.2em 0.5em 0.2em 0.5em;
  border:none;
  border-bottom: 1px solid;
}

.markdown-body.slides table tr {
  border-top: 0;
  background-color: #fff;
}

.markdown-body.slides table tr:nth-child(2n) {
  background-color: inherit;
}

.markdown-body.slides table tbody tr:last-child th, .markdown-body.slides table tbody tr:last-child td {
  border-bottom: none;
}

.markdown-body.slides h1, .markdown-body.slides h2 {
    border-bottom: 0;
}

.night .markdown-body.slides h1,
.night .markdown-body.slides h2,
.night .markdown-body.slides h3,
.night .markdown-body.slides h4,
.night .markdown-body.slides h5,
.night .markdown-body.slides h6 {
    color: black;
}

.markdown-body section > section:last-child {
	margin-bottom: 1.5em !important;
}

/* slides previews get a black background, controlled by js */
.ui-view-area.black {
  background-color: black !important;;
}
