/* for markdown-body */

.markdown-body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    padding-top: 40px;
    padding-bottom: 40px;
    max-width: 758px;
	overflow: visible !important;
}
/*fixed style for bootstrap comflict*/

.markdown-body pre {
    border: inherit !important;
}

.night .markdown-body pre {
  filter: invert(100%);
}

.markdown-body code {
    color: inherit !important;
}

.markdown-body pre code .wrapper {
    display: -webkit-inline-flex;
    display: -moz-inline-flex;
    display: -ms-inline-flex;
    display: -o-inline-flex;
    display: inline-flex;
}

.markdown-body pre code .gutter {
    float: left;
    overflow: hidden;
    -webkit-user-select: none;
    user-select: none;
}

.markdown-body pre code .gutter.linenumber {
    text-align: right;
    position: relative;
    display: inline-block;
    cursor: default;
    z-index: 4;
    padding: 0 8px 0 0;
    min-width: 20px;
    box-sizing: content-box;
    color: #afafaf !important;
    border-right: 3px solid #6ce26c !important;
}

.markdown-body pre code .gutter.linenumber > span:before {
    content: attr(data-linenumber);
}

.markdown-body pre code .code {
    float: left;
    margin: 0 0 0 16px;
}

.markdown-body .gist .line-numbers {
    border-left: none;
    border-top: none;
    border-bottom: none;
}

.markdown-body .gist .line-data {
    border: none;
}

.markdown-body .gist table {
    border-spacing: 0;
    border-collapse: inherit !important;
}

.night .markdown-body .gist table tr:nth-child(2n){
    background-color: #ddd;
}
.night .markdown-body .gist table tr:nth-child(2n+1) {
    background-color: #e3e3e3;
}

.markdown-body code[data-gist-id] {
    background: none;
    padding: 0;
    filter: invert(100%);
}

.markdown-body code[data-gist-id]:before {
    content: ''
}

.markdown-body code[data-gist-id]:after {
    content: ''
}

.markdown-body code[data-gist-id] .blob-num {
    border: unset;
}

.markdown-body code[data-gist-id] table {
    overflow: unset;
    margin-bottom: unset;
}

.markdown-body code[data-gist-id] table tr {
    background: unset;

}

/*fixed style for rtl in pre and code*/

.markdown-body[dir='rtl'] pre {
    direction: ltr;
}

.markdown-body[dir='rtl'] code {
    direction: ltr;
    unicode-bidi: embed;
}

.markdown-body .alert > p {
    margin-bottom: 0;
}

.markdown-body pre.flow-chart,
.markdown-body pre.sequence-diagram,
.markdown-body pre.graphviz,
.markdown-body pre.mermaid,
.markdown-body pre.abc {
    text-align: center;
    background-color: inherit;
    border-radius: 0;
    white-space: inherit;
}

.night .markdown-body pre.graphviz {
    filter: none;
}

.night .markdown-body pre.mermaid .titleText,
.night .markdown-body pre.mermaid text,
.night .markdown-body pre.mermaid .sectionTitle{
    fill: white;
}

.markdown-body pre.flow-chart > code,
.markdown-body pre.sequence-diagram > code,
.markdown-body pre.graphviz > code,
.markdown-body pre.mermaid > code,
.markdown-body pre.abc > code {
    text-align: left;
}

.markdown-body pre.flow-chart > svg,
.markdown-body pre.sequence-diagram > svg,
.markdown-body pre.graphviz > svg,
.markdown-body pre.mermaid > svg,
.markdown-body pre.abc > svg {
    max-width: 100%;
    height: 100%;
}

.night .markdown-body .abc {
    background-color: #fff;
    filter: none;
}

.night pre rect{
    fill: transparent;
}

.markdown-body pre > code.wrap {
    white-space: pre-wrap;       /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
}

.markdown-body .alert > p,
.markdown-body .alert > ul {
    margin-bottom: 0;
}

/* Make details boxes look like on GitHub */
.markdown-body summary {
    display: list-item;
}

.markdown-body summary:focus {
    outline: none;
}

.markdown-body details summary {
    cursor: pointer;
}

.markdown-body details:not([open]) > *:not(summary) {
    display: none;
}

.markdown-body figure {
    margin: 1em 40px;
}

.markdown-body img {
    background-color: transparent;
}
